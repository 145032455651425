<template>
  <div class="HomeView">
    <!-- 当isMobile为true时，显示移动端组件 -->
   <PhoneComponent v-if="isMobile" />
    <!-- 当isMobile为false时，显示其他组件或内容 -->
    <OtherComponent v-else />
  </div>
</template>

<script>
import PhoneComponent from '@/components/PhoneComponent.vue'
import OtherComponent from '@/components/OtherComponent.vue'
export default {
  name: 'HomeView',
  components: {
    PhoneComponent,OtherComponent
  },
  data(){
    return {
      isMobile: false, // 默认不是移动端
      mobileBreakpoint: 750, // 设置移动端阈值
    }
  },
  created() {
    this.checkMobile(); // 创建时检查
    window.addEventListener('resize', this.checkMobile); // 监听窗口大小变化
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < this.mobileBreakpoint;
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile); // 移除监听器
  },
}
</script>
<style scoped lang="scss">
  .HomeView{
    width: 100%;
    height: 100%;
  }
</style>
