<template>
  <div class="PhoneComponent">
    <div class="headerBox" style="    background-color: #ffffff;">
      <img src="@/assets/img/nav_bar_img_h5.c9e07sss9cc.png" alt="" srcset="">
    </div>
    <div class="singinBox">
      <div class="jieshao">
        <div class="text1">
          <div style="padding-right: 10px;">Sell more with</div>
          <div class="yeB">
            <div class="topText">60%</div>
            <div class="bottomText">of users tend to buy the products they need in independent stores.</div>
          </div>
          <!-- <img src="@/assets/img/introduction_badge.926d8bbd2.png" alt="" srcset=""> -->
        </div>
        <div class="text2">open market</div>
        <div class="text3">Start your e-commerce career at zero cost.</div>
        <div class="text4">JetCommerce standalone store*</div>
        <div class="text5">*JetCommerce provides you with a full-link one-stop business solution with brand e-commerce as the core..</div>
      </div>
    </div>
    <div class="denglu">
      <div class="text1">Sign up</div>
      <!-- <div class="text2"><img class="imgsty" src="@/assets/img/dq.png" alt="" srcset=""> United States Merchant <span>Switch country</span></div> -->
      <div class="text3">
        <a href="https://jetcommerce.shop/">Contact us to complete your registration</a>
      </div>
      <!-- <el-input v-model="input" placeholder="Enter phone number or email">
        <div class="usBox" slot="prefix">US +1</div>
      </el-input> -->
      <div class="text4">
        <el-checkbox v-model="checked"></el-checkbox>
        <span>What are you still hesitating about? Contact us now!</span>
      </div>
      <div class="btns">
        <a href="https://to0.xyz/j/BqvbaHhZ9231"><el-button type="danger" style="width: 100%;background: #FE2C55;">Continue</el-button></a>
      </div>
      <!-- <div class="btns">
        <el-button plain style="width: 100%;">
          <div class="imgBox">
            <img src="@/assets/img/1.svg" alt="" srcset=""> Sign up with JetCommerce independent station account
          </div>
        </el-button>
      </div> -->
      <!-- <div class="btns">
        <el-button plain style="width: 100%;" icon="">
          <div class="imgBox">
            <img src="@/assets/img/2.svg" alt="" srcset=""> Sign up with Google
          </div>
        </el-button>
      </div> -->
      <div class="text5">
        60% of users tend to buy the products they need in independent stores.<br>
 *Source: JET Marketing Science Global Retail Path to Purchase conducted by Material, August 2023 (US results).
      </div>
     <!--  <div class="text6"> Have a JetCommerce independent station Shop or JetCommerce independent station For Business account? <span>Log in</span> </div> -->
    </div>
    <div class="videoBox">
      <div style="width: 100%;background:#000000;height: 15px;"></div>
      <div class="videoInfo">
        <div class="text1">Shopping made simple</div>
        <div class="text2">With the support of Jet Commerce's unique discovery engine, the products on Jet Commerce's independent website can reach customers and display and sell products through platforms such as TIKTOK and FACEBOOK that have strategic cooperation with JET.</div>
        <div class="videoSty">
          <div class="viideoItem" v-for="(item,index) in  videoList" :key="index">
            <!-- loop muted playsinline preload="metadata"  ref="videoRef"  autoplay  -->
            <!-- <video class="videoSty"  style="border: 1px solid red;">
              <source :src="item.url" type="video/mp4">
            </video> -->
            <video loop muted playsinline preload="metadata"  ref="videoRef"  autoplay  class="videoSty"
                   :src="item.url" :poster="item.url + '?x-oss-process=video/snapshot,t_1,m_fast'"></video>
            <div class="title">{{ item.title }}</div>
            <!-- <div class="subTitle">{{ item.subTitle }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="successBox">
      <div class="text1">Seller success on JetCommerce independent station Shop</div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in swiperList" :key="index">
              <img class="imgSty" :src="item.url" alt="">
              <div class="textBox">
                <div class="text1">{{ item.title}}</div>
                <div class="text2">{{ item.subTitle}}</div>
                <div class="text3" v-for="(item2,index2) in item.children" :key="index2">
                  <div class="text4">{{ item2.name }}</div>
                  <div class="text5">{{ item2.text }}</div>
                </div>
              </div>
            </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="becomeBox">
      <div class="text1">Become a JetCommerce independent station Shop seller today</div>
      <div class="text2">Sign up before September 30th to sell on JetCommerce independent station Shop with no referral fees for the first 90 days*! No matter your business size, follower count or content expertise, you can find the tools and partners you need to grow. (*<span>Terms and Conditions</span>)</div>
      <el-collapse class="cpllBox" v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item,index) in becomeList" :key="index" :name="index">
          <template slot="title">
            <span class="title">{{ item.name }}</span>
            <img style="width: 15px;height: 15px" v-if="judgeActive(index)!=-1" src="@/assets/img/jian.png" alt="" srcset="">
            <img style="width: 15px;height: 15px" v-else src="@/assets/img/jia.png" alt="" srcset="">
          </template>
          <div class="text">{{ item.text }}</div>
          <div v-if="index === 1" class="index1">
            Ready to sell? Before starting, get to know our document requirements and our "Restricted and prohibited products guidelines". View details
          </div>
          <div class="time">{{ item.time }}</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="qaBoox">
      <div class="text1">FAQ</div>
      <el-collapse class="cpllBox2">
        <el-collapse-item>
          <template slot="title"><span class="title">1. What documentation is required to register for JetCommerce independent station Shop?</span></template>
          <div>
            For individual applicants, the applicant must provide the following information and documents for verification purposes:<br/>
            - Legal Name (including your first name and last name, with middle name optional)<br/>
            - Date of birth<br/>
            - Residential Address<br/>
            - A photo copy of a valid U.S. government-issued ID from the following list:<br/>
            - U.S. Passport<br/>
            - U.S. Driver's License<br/>
            - ID Expiration Date<br/>
            - The last 4 Digits of your Social Security number (SSN) or Individual Taxpayer Identification Number (ITIN)<br/>
            For business applicants, the applicant must provide company registration information, including but not limited to:<br/>
            - Business Type<br/>
            - Company Name<br/>
            - Employer Identification Number (EIN)<br/>
            - Registered business address<br/>
            - Business address (if different from registered business address)<br/>
            - Beneficial owners of the company being registered may need to provide:<br/>
            - Legal Name<br/>
            - Date of birth<br/>
            - Residential address<br/>
            - Percentage of ownership (only if "beneficial owner" is chosen)<br/>
            - The Last 4 digits of SSN or ITIN (except for public companies)<br/>
          </div>
        </el-collapse-item>
        <el-collapse-item>
          <template slot="title"><span class="title">2. What are the costs involved?</span></template>
          <div>Referral fee is a specific percentage of the total order amount. JetCommerce independent station Shop is currently in the early testing stage in the US and the commission rate will be further updated in due course.</div>
        </el-collapse-item>
        <el-collapse-item>
          <template slot="title"><span class="title">3. Is there any follower requirement to join JetCommerce independent station Shop as a seller?</span></template>
          <div>No, you can join JetCommerce independent station Shop and start selling via shoppable videos or livestreams, even if you register a new JetCommerce independent station account and do not have any followers.</div>
        </el-collapse-item>
        <el-collapse-item>
          <template slot="title"><span class="title">4. Which products are prohibited or restricted for sale on JetCommerce independent station Shop?</span></template>
          <div>
            Approval is required for sellers to sell any products listed in JetCommerce independent station Shop Restricted Products Guidelines.<br/>
            Sellers are not allowed to sell any product listed in JetCommerce independent station Shop Prohibited Products Guidelines.<br/>
          </div>
        </el-collapse-item>
        <el-collapse-item>
          <template slot="title"><span class="title">5. How can I promote my products for JetCommerce independent station Shop?</span></template>
          <div>You can leverage platform discounts, coupons, free shipping promotions or incentive program to promote your products. Also, you can create a Shop Ad campaign to maximize traffic and sales for your JetCommerce independent station Shop by using powerful targeting abilities and fully integrated user signals. Learn more.</div>
        </el-collapse-item>
        <!-- <el-collapse-item>
          <template slot="title"><span class="title">6. Can I connect my e-commerce store to JetCommerce independent station Shop?</span></template>
          <div>Yes, you can use apps and services to connect your e-commerce store and sync your inventory, order and sales data. This includes platforms like Shopify, WooCommerce, BigCommerce, Adobe / Magento, Salesforce Commerce Cloud and Amazon. Explore more here. </div>
        </el-collapse-item> -->
      </el-collapse>
    </div>
    <div class="otherBox">
      <div class="otherItem" v-for="(item,index) in otherBoxList" :key="index">
        <div class="name1">{{ item.name }}</div>
        <div class="name2" v-for="(item2,index2) in item.list" :key="index2">
          <a href="https://jetcommerce.shop/">{{ item2 }}</a>
        </div>
      </div>
    </div>
    <div class="infoBox">
      <div class="div1">
        <img src="@/assets/img/dy.png" alt="" srcset="">
        <img src="@/assets/img/fs.png" alt="" srcset="">
        <img src="@/assets/img/ins.png" alt="" srcset="">
      </div>
      <div class="div2" >United States</div>
      <div class="div3">Policy</div>
      <div class="div4">Cookie policy</div>
      <div class="div5">Intellectual property protection</div>
      <div class="div6">C 2024 JetCommerce independent station. All rights reserved</div>
    </div>
    <!-- <div class="backTop" @click="scrollToTop" v-if="scrollHeight > 800">Sign up now</div> -->

    <a href="https://to0.xyz/j/BqvbaHhZ9231">
      <div class="dy">
        <img style="margin-right:20px;" src="@/assets/img/whatsapp-logo-0DBD89C8E2-seeklogo.com.png" alt="" srcset="">
        Contact us WhatsApp 
        </div>
      </a> 
    </div>
    
</template>
  
  <script>
  import Swiper from 'swiper'; // 注意引入的是Swiper
  import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
  name: "PhoneComponent",
  data() {
    return {
      activeNames: [0],
      checked: "",
      input: "",
      scrollHeight: 0,
      videoList: [
        {
          url: require("@/assets/img/LIVE_Shopping.mp4"),
          title: "LIVE Shopping",
          subTitle:
            "Promote, sell and engage with your audience, all in real time.",
        },
        {
          url: require("@/assets/img/Shop_tab_Zip.mp4"),
          title: "Shoppable Video",
          subTitle:
            "Create entertaining videos with your products just a tap away.",
        },
        {
          url: require("@/assets/img/Shoppable_video.mp4"),
          title: "Shop Page",
          subTitle: "Display your product catalog within your own in-app shop.",
        },
        {
          url: require("@/assets/img/Store_page.mp4"),
          title: "Shop Tab",
          subTitle:
            "Display your products on a centralized marketplace within JetCommerce independent station.",
        },
      ],
      swiperList: [
        {
          url: require("@/assets/img/e7cd966c454d48d8b436566d066d8317~tplv-aphluv4xwc-origin-image.png"),
          title: "Brandon The Plant Guy",
          subTitle:
            "We provide exceptional store operations services to ensure a seamless experience and seize the opportunities every brand or business has in this ever-evolving digital age.”",
          children: [
            { name: ">1.5M", text: "Total LIVE Views Since Launch" },
            { name: ">15K", text: "Orders Since Launch" },
            { name: ">$350K", text: "LIVE Shopping Sales Since Launch" },
          ],
        },
        {
          url: require("@/assets/img/5d6547862950425bbcd3b23185e38dad~tplv-aphluv4xwc-origin-image.png"),
          title: "Soo Slick",
          subTitle:
            "Soo Slick, a minority women-owned fashion brand, activated a JetCommerce independent station Shop Creator Affiliate Open Plan to allow creators to feature products at competitive prices through shoppable content on JetCommerce independent station. The videos have experienced weekly top performing content success.",
          children: [
            { name: ">$1M", text: "Creator Affiliate Sales Since Launch" },
            { name: ">200M", text: "Affiliate Content Views Since Launch" },
            { name: ">$50K", text: "Creator Peak Total Sales (Single Video)" },
          ],
        },
        {
          url: require("@/assets/img/809cac8a2dff4bedb92dc101ecb1bd8a~tplv-aphluv4xwc-origin-image.png"),
          title: "Wyze",
          subTitle:
            "Wyze, a smart home products start-up, activated a JetCommerce independent station Shop Creator Affiliate Open Plan to allow creators to feature products at competitive prices through shoppable content on JetCommerce independent station. The videos have experienced weekly top performing content success. Wyze has risen to one of the top selling Electronic Shops on JetCommerce independent station Shop.",
          children: [
            { name: ">$1M", text: "Creator Affiliate sales (60 days)" },
            { name: ">10M", text: "Content views (60 days)" },
            { name: "$100K", text: "Peak total Sales (single video)" },
          ],
        },
        {
          url: require("@/assets/img/cc9c9686d57044a586004ddea395a997~tplv-aphluv4xwc-origin-image.png"),
          title: "Twenties Beauty",
          subTitle:
            "Twenties Beauty is committed to making Korean beauty more accessible in the US, and sells a variety of brands, including their own proprietary brand, Plan 36.5. In 30 days, their JetCommerce independent station Shop creator affiliate and self-produced content experienced viral success and has risen to one of the top selling Beauty Shops on JetCommerce independent station Shop.",
          children: [
            { name: ">$500K", text: "Sales (30 days)" },
            { name: ">25K", text: "Orders (30 days)" },
            { name: ">$60K", text: "Peak total Sales (single video)s" },
          ],
        },
      ],
      becomeList: [
        {
          name: "Sign up",
          text: "Sign up to JetCommerce independent station Shop using a US phone number and email address.",
          time: "2 minutes",
        },
        {
          name: "Complete the application",
          text: "Verify your identity with a certificate of incorporation, US passport or a US driver's license.",
          time: "30 minutes",
        },
        {
          name: "Get approved",
          text: "Check your email for confirmation of your approval. *Actual application review time may vary",
          time: "2–6 days",
        },
        {
          name: "Link your JetCommerce independent station account",
          text: "Link your JetCommerce independent station account to your shop and start selling!",
          time: "5 minutes",
        },
      ],
      otherBoxList:[
        {
          name:"Company",
          list:['JetCommerce independent station','JetCommerce independent station for Business','CapCut']
        },
        {
          name:"Product",
          list:['Seller Center','Seller Center app store','LIVE Manager','CapCut creative suite','Partner Desktop']
        },
        {
          name:"Support",
          list:['Academy','Feature guide','Policy Center','Contact us','File a complaint']
        },
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    var swiper = new Swiper(".swiper-container", {
      infinite: true,
      slidesPerView: 1,
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        clickableClass : 'my-pagination-clickable',
      },
    });
  },
  methods: {
    handleChange(val) {},
    //判断是否打开
    judgeActive(data) {
      return this.activeNames.indexOf(data);
    },
    handleScroll() {
      this.scrollHeight = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
  <style lang="scss">
  .PhoneComponent{
    .cpllBox {
      .el-collapse-item__content {
        padding-bottom: 10px;
      }
    }
    .cpllBox2 {
      .el-collapse-item__content {
        padding: 10px 0 15px 0;
        background: rgba($color: #f3f4f6, $alpha: 1);
        color: rgba(0, 0, 0, 0.54);
      }
      .el-collapse-item {
        background: rgba($color: #f3f4f6, $alpha: 1);
      }
      .el-collapse-item__header {
        padding: 10px 0;
        height: auto;
        min-height: 48px;
        background: rgba($color: #f3f4f6, $alpha: 1);
      }
    }
    .denglu {
      .el-input--prefix .el-input__inner {
        padding-left: 60px;
      }
    }
    .successBox{
      .swiper-pagination-bullet {
        width: 30px;
        height: 5px;
        border-radius: 20px;
        background-color: #ccc;
        opacity: 0.8;
      }
      
      .swiper-pagination-bullet-active {
        width: 30px;
        height: 5px;
        border-radius: 20px;
        background-color: #FF4D4A;
        opacity: 1;
      }
    }
  }

</style>
<style scoped lang="scss">
  .button4 {
    //background-color: #000000;
    color: #bbb9b9;
    //border: 2px solid #e7e7e7;
    width: 100px;
    height: 35px;
    margin-left: 2%;
    text-align: center; /* Center text horizontally */
    line-height: 35px; /* Center text vertically */
}

.button4:hover {background-color: #979896;}
  
.PhoneComponent {
  width: 100vw;
  .headerBox {
    font-weight: 500;
    background-color: #121212;
    color: #fff;
    height: 52px;
    top: 0;
    position: fixed;
    width: calc(100% - 32px);
    z-index: 10000;
    padding: 0 16px;
    display: flex;
    align-items: center;
    img {
      height: 28px;
    }
  }
  .singinBox {
    width: calc(100vw - 40px);
    background: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 14px 20px;
    .jieshao {
      width: 100%;
      margin: 60px 0px 0 0;
      .text1 {
        width: 100%;
        font-size: 50px;
        font-weight: 700;
        // line-height: 56px;
        color: #fff;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .yeB{
          width: 100px;
          min-width: 100px;
          max-width: 100px;
          min-height: 100px;
          max-height: 100px;
          height: 100px;
          padding: 10px;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          font-size: 11px;
          background: #F4F000;
          border-radius: 100%;
          transform: rotate(20deg);
          color: #000;
          .topText{
            font-size: 22px;
            font-weight: 900;
          }
          div{
            text-align: center;
          }
        }
        img {
          width: 102px;
          height: 102px;
          max-width: 102px;
          min-height: 102px;
        }
      }
      .text2 {
        font-size: 50px;
        font-weight: 700;
        line-height: 72px;
        color: rgb(235, 37, 76);
      }
      .text3 {
        color: #fff;
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        margin: 5px 0 15px 0;
      }
      .text4 {
        color: #25f4ee;
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
      }
      .text5 {
        color: hsla(0, 0%, 100%, 0.4);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 15px 0;
      }
    }
  }
  .denglu {
    width: calc(100% - 60px);
    padding: 30px 30px 30px 30px;
    background: #fff;
    font-size: 14px;
    .text1 {
      font-size: 26px;
      color: #121212;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .text2 {
      font-size: 14px;
      color: #121212;
      font-weight: 500;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .imgsty {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      span {
        color: #379afe;
        margin-left: 10px;
        font-weight: 550;
      }
    }
    .text3 {
      font-size: 14px;
      color: #121212;
      font-weight: 600;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .usBox {
      height: 100%;
      display: flex;
      align-items: center;
      color: #000;
      padding: 0 5px;
    }
    .text4 {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      margin: 10px 0;
      display: flex;
      align-items: center;
      span {
        padding-left: 10px;
        text-align: left;
      }
    }
    .btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .text5 {
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.55);
      line-height: 23px;
      font-weight: 400;
      .spanText {
        font-weight: 500;
        color: #000;
      }
      .spanText:hover {
        color: #379afe;
        cursor: pointer;
      }
    }
    .text6 {
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
      margin: 25px 0 0 0;
      span {
        color: #379afe;
        font-weight: 500;
      }
    }
  }
  .videoBox{
    .videoInfo {
      width: calc(100% - 60px);
      padding: 30px 30px 0 30px;
      background: #141414;
      color: #fff;
      .text1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
      }
      .text2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        margin-top: 10px;
        margin-bottom: 40px;
      }
      .videoSty {
        padding-bottom: 20px;
        width: 100%;
        overflow-y: auto;
        display: flex;
        .viideoItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          // justify-content: center;
          .videoSty {
            width: 260px;
            height: 420px;
            margin-bottom: 15px;
          }
          .title {
            font-size: 20px;
            font-weight: 700;
            line-height: 50px;
          }
          .subTitle {
            text-align: center;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 400;
            color: #ccc;
            line-height: 18px;
          }
        }
      }
      .videoSty::-webkit-scrollbar {
        height: 0;
      }
    }
  }
  .successBox {
    width: calc(100% - 60px);
    padding: 30px 30px 10px 30px;
    background: #f2f5f7;
    .text1 {
      font-size: 26px;
      color: #121212;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .swiper-container {
      width: 100%;
      padding-bottom: 30px;
    }
    .swiper-slide {
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      .imgSty {
        width: 100%;
        height: 110px;
      }
      .textBox {
        padding: 10px 15px 20px 15px;
        .text1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 5px;
        }
        .text2 {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
        .text3 {
          margin-top: 15px;
        }
        .text4 {
          font-size: 20px;
          line-height: 30px;
          font-weight: 600;
        }
        .text5 {
          font-size: 13px;
          line-height: 14px;
        }
      }
    }
  }
  .becomeBox {
    width: calc(100% - 60px);
    padding: 30px 30px 30px 30px;
    background: #faedf0;
    .text1 {
      font-size: 26px;
      color: #121212;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .text2 {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      span {
        color: rgb(54, 154, 254);
      }
    }
    .cpllBox {
      padding: 10px 18px;
      background: #fff;
      border-radius: 15px;
      margin-top: 25px;
      .title {
        color: rgba(0, 0, 0, 0.92);
        font-size: 16px;
        font-weight: 700;
      }
      .text {
        color: rgba(0, 0, 0, 0.92);
        font-size: 13px;
        font-weight: 550;
      }
      .index1 {
        width: 100%;
        color: rgba(0, 0, 0, 0.92);
        font-size: 13px;
        font-weight: 550;
        padding: 8px;
        background: #ebf6ff;
        margin: 15px 0;
        border-radius: 10px;
      }
      .time {
        color: #8c8c8c;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  .qaBoox {
    width: calc(100% - 60px);
    padding: 30px 30px 30px 30px;
    background: #f3f4f6;
    .text1 {
      font-size: 26px;
      color: #121212;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .cpllBox2 {
      // padding:10px 18px;
      background: #fff;
      border-radius: 15px;
      margin-top: 25px;
      .title {
        color: rgba(0, 0, 0, 0.92);
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
  .otherBox{
    width: 100%;
    padding-bottom: 30px;
    background: #000;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    border-bottom: 1px solid rgba($color: #999, $alpha: .33);
    .otherItem{
      width: calc(49% - 20px);
      padding:10px 0 0 20px;
      .name1{
        font-size: 18px;
        font-weight: 700px;
        margin-bottom: 15px;
      }
      .name2{
        color: #999999;
        font-size: 14px;
        font-weight: 300px;
        margin-bottom: 15px;
      }
    }
  }
  .infoBox{
    padding: 40px 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;
    font-size: 14px;
    .div1{
      margin-bottom: 40px;
      img{
        margin-left: 5px;
        width: 30px;
        height: 30px;
      }
    }
    .div2{}
    .div3{
      color: #999;
      margin-top: 40px;
    }
    .div4{
      color: #999;
      margin-top: 15px;
    }
    .div5{
      color: #999;
      margin-top: 15px;
    }
    .div6{
      color: #999;
      margin-top: 40px;
    }
  }
  .backTop {
    position: fixed;
    z-index: 2000;
    bottom: 60px;
    width: 80px;
    text-align: center;
    left: calc(50% - 50px);
    padding: 10px;
    color: #fff;
    border-radius: 40px;
    font-size: 13px;
    background: #fe2c55 !important;
  }
  .dy{
    width: calc(100% - 60px);
    position: fixed;
    bottom: 10px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding:10px 10px;
    background: rgba($color: #121212, $alpha: .9);
    font-size: 17px;
    color: #fff;
    z-index: 3000;
    font-weight: 400;
    img{
      height: 30px;
      margin-right: 20px;
    }
  }
}
a {
  text-decoration: none;   /* 去掉下划线 */
  color: inherit;          /* 继承父元素的颜色 */
  outline: none;           /* 去掉点击链接时的轮廓线 */
  background: transparent; /* 去掉背景色 */
}
</style>
  